<template>
  <div>
    <c-table
      ref="table"
      title="가스항목 목록"
      tableId="gas"
      :columns="grid.columns"
      :data="grid.data"
      :editable="editable"
      :filtering="false"
      :columnSetting="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" :showLoading="false" label="추가" icon="add" @btnClicked="add" />
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="PUT"
            label="저장" 
            icon="save" 
            @beforeAction="saveGas"
            @btnCallback="saveGasCallback"/>
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-gas-item',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      if(this.$store.getters.user.innerFlag === 'N') {
        this.editable = false;
      } else {
        this.editable = this.$route.meta.editable;
      }
      // url setting
      this.listUrl = selectConfig.sop.swp.gas.list.url
      this.saveUrl = transactionConfig.sop.swp.gas.save.url
      // code setting
      this.$comm.getComboItems('SOP_WORK_TYPE_CD').then(_result => {
        _result = this.$_.filter(_result, { attrVal1: 'Y' })
        this.grid.columns = [
          {
            name: 'gasName',
            field: 'gasName',
            label: '가스항목명',
            align: 'left',
            sortable: true,
            required: true,
            style: 'width:300px',
            type: 'text',
          },
          {
            name: 'safetyStandards',
            field: 'safetyStandards',
            label: '안전기준',
            align: 'center',
            sortable: true,
            style: 'width:120px',
            type: 'text',
          },
          {
            name: 'workTypes',
            field: 'workTypes',
            label: '작업종류',
            align: 'left',
            sortable: true,
            required: true,
            style: 'width:300px',
            type: 'multiSelect',
            isObject: true,
            valueText: 'sopWorkTypeName',
            valueKey: 'sopWorkTypeCd',
            itemText: 'codeName',
            itemValue: 'code',
            comboItems: _result,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            sortable: true,
            style: 'width:100px',
            type: 'check',
            true: 'Y',
            false: 'N',
          },
        ]
      });
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        companyCd: this.$store.getters.user.companyCd,
        plantCd: this.param.plantCd,
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    add() {
      let date = new Date();
      if (!this.grid.data) {
        this.grid.data = [];
      }
      this.grid.data.splice(0, 0, {
        sopGasId: String(date.getTime()),  // 가스번호
        companyCd: this.$store.getters.user.companyCd,
        plantCd: this.param.plantCd,
        gasName: '',  // 가스항목명
        safetyStandards: '',  // 안전기준
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        workTypes: [],
        editFlag: 'C'
      })
    },
    saveGas() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, item => {
              item.regUserId = this.$store.getters.user.userId
              item.chgUserId = this.$store.getters.user.userId
            });
            
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveGasCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
